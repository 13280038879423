import React from "react"
import linkBuilder from "../../modules/linkBuilder";
import { campaignList } from "./campaign-grid.module.css"
import ActionCard from "../action-card/action-card";


const CampaignGrid = ({ campaigns }) => {
    return (
        <div className={campaignList}>
            {campaigns?.map((campaign) => {
                let link = linkBuilder.campaign(campaign);
                return <ActionCard key={`campaign-${campaign.id}`} link={link} title={campaign.name} image={campaign.image} textAlign="center" />
            })}
        </div>
    );
};

export default CampaignGrid;
