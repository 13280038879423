// extracted by mini-css-extract-plugin
export var card = "search-provider-module--card--7cda2";
export var cardBody = "search-provider-module--card-body--367a4";
export var cardDescription = "search-provider-module--card-description--acb7d";
export var cardImg = "search-provider-module--card-img--3203b";
export var cardTitle = "search-provider-module--card-title--f08ea";
export var hitsContainer = "search-provider-module--hits-container--311b0";
export var inputs = "search-provider-module--inputs--bd7b1";
export var loadMoreButton = "search-provider-module--load-more-button--2edf6";
export var searchContentWrapper = "search-provider-module--search-content-wrapper--58ae9";
export var searchInput = "search-provider-module--search-input--26d4b";
export var searchPageTitle = "search-provider-module--search-page-title--3dcf1";