// extracted by mini-css-extract-plugin
export var controlBase = "carousel-module--control-base--bc06c";
export var controlIcon = "carousel-module--control-icon--aa442";
export var flickingHit = "carousel-module--flicking-hit--af6a0";
export var indicatorsActiveOff = "carousel-module--indicators-active-off--aa2e2";
export var indicatorsActiveOn = "carousel-module--indicators-active-on--26c4b";
export var indicatorsBase = "carousel-module--indicators-base--8ca64";
export var indicatorsWrapper = "carousel-module--indicators-wrapper--c3741";
export var itemBase = "carousel-module--item-base--b0e7b";
export var itemWrapper = "carousel-module--item-wrapper--0ca64";
export var rootBase = "carousel-module--root-base--8a7d8";
export var rootLeftControl = "carousel-module--root-leftControl--af9a2";
export var rootRightControl = "carousel-module--root-rightControl--9cafe";
export var scrollContainerBase = "carousel-module--scrollContainer-base--e3dd8";
export var scrollContainerSnap = "carousel-module--scrollContainer-snap--a666a";