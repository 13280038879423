import React from "react"
import {
  introContainer,
  introImageContainer,
  introImage,
  introTitle,
  introDescription,
  introContent,
} from "./home-intro.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import RichTextContent from "../rich-text-content/rich-text-content"

const HomeIntro = ({ title, description, image }) => {
  if (!image) {
    console.log(`Image not found for HomeIntro with title "${title}"`)
    return null
  }
  
  return (
    <div className={introContainer}>
      <div className={introContent}>
        <p className={introTitle}>{title}</p>
        <div className={introDescription}>
          <RichTextContent content={description} addListMarker={true} />
        </div>
      </div>
      <div className={introImageContainer}>
        <GatsbyImage
          className={introImage}
          layout="fullWidth"
          aspectRatio={3 / 1}
          alt={image.title ? image.title : ""}
          image={image.gatsbyImageData}
        />
      </div>
    </div>
  )
}

export default HomeIntro
