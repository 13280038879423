import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { cardTitle, cardImage, cardWrapper } from "./cta-image-card.module.css"
import { Link } from "gatsby"

const CtaImageCard = ({ title, image, link, label }) => {
  if (!image) {
    console.log(`Image not found for CtaImageCard with link "${link}"`)
    return null
  }

  return (
    <Link to={link}>
      <article className={cardWrapper}>
        <GatsbyImage
          className={cardImage}
          layout="fullWidth"
          aspectRatio={3 / 1}
          alt={image.title ? image.title : ""}
          image={image.gatsbyImageData}
        />
        <div className={cardTitle}>
          <div>
            {label && <h2 style={{ color: "#bababa", margin: 0 }}>{label}</h2>}
            <h1>{title}</h1>
          </div>
        </div>
      </article>
    </Link>
  )
}

export default CtaImageCard
