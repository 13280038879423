import React from "react"
import linkBuilder from "../../modules/linkBuilder";
import EntryCard from "../entry-card/entry-card"
import { articleList } from "./newest-articles.module.css"


const NewestArticles = ({ articles, excludedEntries }) => {

    return (
        <>
            <div className={articleList}>
                {articles.filter(article => !excludedEntries.includes(article.contentful_id)).slice(0, 6).map((article) => {
                    let link = linkBuilder.article(article);
                    return (
                        <EntryCard key={`newest-article-${article.id}`} title={article.title} link={link} image={article.image} />
                    )
                })}
            </div>
        </>
    );
};

export default NewestArticles;
