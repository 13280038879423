import React from "react"
import CtaImageCard from "../cta-image-card/cta-image-card"
import linkBuilder from "../../modules/linkBuilder"
import { wrapper, entry1, entry2, entry3 } from "./featured-grid.module.css"

const FeaturedGrid = ({ featuredEntries }) => {
  return (
    <>
      <div className={wrapper}>
        {featuredEntries?.map((entry, index) => {
          let link, styling, label
          let title = entry.title || entry.name

          switch (index) {
            case 0:
              styling = entry1
              break
            case 1:
              styling = entry2
              break
            case 2:
              styling = entry3
              break
            default:
              styling = null
              break
          }

          switch (entry.__typename) {
            case "ContentfulArticle":
              link = linkBuilder.article(entry)
              label = "Artikel"
              break
            case "ContentfulDownload":
              link = linkBuilder.download(entry)
              label = entry.downloadType
              break
            case "ContentfulCampaign":
              link = linkBuilder.campaign(entry)
              label = "Thema"
              break
            case "ContentfulDesk":
              link = linkBuilder.desk(entry)
              label = 'Alles over...'
              break
            case "ContentfulDeskCategory":
              link = linkBuilder.deskCategory(entry)
              label = 'Alles over...'
              break
            default:
              link = null
              label = null
          }

          return (
            <div key={title} className={styling}>
              <CtaImageCard
                key={`articlecard-${entry.id}`}
                title={title}
                image={entry.image}
                link={link}
                label={label}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default FeaturedGrid
