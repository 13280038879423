import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  cardWrapper,
  cardImage,
  cardTitle,
  cardLink,
} from "./action-card.module.css"

const ActionCard = ({
  link,
  title,
  image,
  page = "*",
  textAlign = "start",
  ...props
}) => {
  if (!image) {
    console.log(`Image not found for ActionCard with link "${link}"`)
    return null
  }

  return (
    <Link
      to={link}
      className={cardLink}
      {...props}
      data-page={page.toLowerCase()}
    >
      <article className={cardWrapper}>
        <GatsbyImage
          className={cardImage}
          layout="fullWidth"
          aspectRatio={3 / 1}
          alt={image.title ? image.title : ""}
          image={image.gatsbyImageData}
        />
        <div className={cardTitle} style={{ textAlign: textAlign }}>
          <h1>{title}</h1>
        </div>
      </article>
    </Link>
  )
}

export default ActionCard
