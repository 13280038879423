import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ExternalLink,
  ExternalLinkImageWrapper,
  SideColumnTitle,
  SideColumnText,
  content,
} from "./media-kit.module.css"
import SideColumnWrapper from "../side-column/side-column-wrapper/side-column-wrapper"
import RichTextContent from "../rich-text-content/rich-text-content"

const MediaKit = ({ title, description, link, image }) => {
  if (!image) {
    console.log(`Image not found for mediakit`)
    return null
  }
  return (
    <SideColumnWrapper>
      <div className={ExternalLink}>
        <GatsbyImage
          image={image.gatsbyImageData}
          className={ExternalLinkImageWrapper}
          alt={image.title ? image.title : ""}
        />

        <div className={content}>
          <p className={SideColumnTitle}>{title}</p>

          <p className={SideColumnText}>
            <RichTextContent content={description} addListMarker={true} />
          </p>

          <a
            className="link"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            naar Mediakit
          </a>
        </div>
      </div>
    </SideColumnWrapper>
  )
}

export default MediaKit
