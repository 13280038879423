import { Link } from "gatsby"
import React from "react"
import linkBuilder from "../../modules/linkBuilder"
import {
  cardWrapper,
  cardListWrapper,
  cardTitle,
  cardIcon,
} from "./hero-menu.module.css"

const HeroMenu = ({ heroList }) => {
  return (
    <div className={cardListWrapper}>
      {heroList.map((hero) => {
        if (hero.icon && hero.icon.mimeType === "image/svg+xml") {
          return (
            <Link
              to={linkBuilder.desk(hero)}
              key={hero.id}
              className={cardWrapper}
            >
              <img
                className={cardIcon}
                src={hero.icon.file.url}
                alt="SVG Icon for desk"
              />
              <p className={cardTitle}>{hero.name}</p>
            </Link>
          )
        }
        return null
      })}
    </div>
  )
}

export default HeroMenu
