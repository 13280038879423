import React from "react"
import { aside, footerList, pageIntro } from "./side-column-new.module.css"
import CtaDownload from "./cta-download/cta-download"
import CtaExternalLink from "./cta-external-link/cta-external-link"
import CtaNewsLetter from "./cta-newsletter/cta-newsletter"
// import CtaSearch from "./cta-search/cta-search"
import CtaSponsorInfo from "./cta-sponsor-info/cta-sponsor-info"
import DisplayTracking from "../tracking/display-tracking"

import SpotifyEmbed from "../spotify-embed/spotify-embed"
import MediaKit from "../media-kit/media-kit"
import EntryList from "../entry-list/entry-list"

import SideColumnWrapper from "./side-column-wrapper/side-column-wrapper"
import HomeIntro from "../home-intro/home-intro"
import CtaProduct from "./cta-product/cta-product"

const SideColumnNew = ({
  className,
  callToActions,
  pageContentfulId,
  spotifyEmbed,
  pageTitle,
  pagePath,
  showSponsorInfo,
  tools,
  whitepapers,
  intro,
  mediakit,
}) => {
  // Only use CTAs when type is created/designed
  if (callToActions)
    callToActions = callToActions.filter((cta) => {
      return (
        cta.__typename === "ContentfulExternalLink" ||
        cta.__typename === "ContentfulDownload" ||
        cta.__typename === "ContentfulProductBeta"
      )
    })

  return (
    <aside className={className ? `${aside} ${className}` : aside}>
      {intro && (
        <SideColumnWrapper>
          <div className={pageIntro}>
            <HomeIntro
              title={intro.title}
              description={intro.description}
              image={intro.image}
            />
          </div>
        </SideColumnWrapper>
      )}

      {callToActions &&
        callToActions.length > 0 &&
        callToActions.map((cta, index) => {
          if (cta.__typename === "ContentfulExternalLink") {
            return (
              <CtaExternalLink
                position={index + 1}
                key={cta.contentful_id}
                cta={cta}
                displayPosition="sideColumn"
                displayPageContentfulId={pageContentfulId}
                displayPageTitle={pageTitle}
              />
            )
          } else if (cta.__typename === "ContentfulProductBeta") {
            return <CtaProduct
              key={cta.contentful_id}
              cta={cta}
              displayPosition="sideColumn"
              displayPageContentfulId={pageContentfulId}
              displayPageTitle={pageTitle} />
          } else if (cta.__typename === "ContentfulDownload") {
            return (
              <DisplayTracking
                key={cta.contentful_id}
                contentfulId={cta.contentful_id}
                contentTitle={cta.title}
                contentType="download"
                displayPosition="sideColumn"
                displayPageContentfulId={pageContentfulId}
                displayPageTitle={pageTitle}
                displayPagePath={pagePath}
                formFields={cta.formFields}
              >
                <CtaDownload
                  position={index + 1}
                  cta={cta}
                  displayPagePath={pagePath}
                  displayPageTitle={pageTitle}
                  displayPageContentfulId={pageContentfulId}
                />
              </DisplayTracking>
            )
          }
        })}

      {showSponsorInfo && <CtaSponsorInfo position={1} />}

      {((callToActions && callToActions.length <= 1) ||
        (!callToActions && !showSponsorInfo)) && <CtaNewsLetter position={2} />}

      {spotifyEmbed && (
        <SpotifyEmbed title={spotifyEmbed.title} link={spotifyEmbed.link} />
      )}

      {mediakit && (
        <MediaKit
          title={mediakit.title}
          description={mediakit.description}
          image={mediakit.image}
          link={mediakit.link}
        />
      )}

      {/* FooterLists */}
      <div className={footerList}>
        {tools && (
          <EntryList title="Verbeter jezelf met onze tools" items={tools} />
        )}

        {whitepapers && (
          <EntryList title="Trending whitepapers" items={whitepapers} />
        )}
      </div>
    </aside>
  )
}

export default SideColumnNew
