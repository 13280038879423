import React from "react"

import {
  inputs,
  hitsContainer,
  searchInput,
  loadMoreButton,
} from "./search-provider.module.css"

import {
  Configure,
  InstantSearch,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch"
import MenuSelectWidget from "../menu-select-widget/menu-select-widget"
import CustomSearchBox from "../custom-search-box/custom-search-box"
import PillMenu from "../pill-menu/pill-menu"
import { FaPlus } from "react-icons/fa"
import EntryCard from "../entry-card/entry-card"
import { indexName, searchClient } from "../../services/search-client"
import imageApi from "../../modules/imageApi"

const transformItems = (items) => {
  const sortOrder = ["Trending", "Nieuw"]
  const sortedItems = []
  const remainingItems = []

  items.forEach((item) => {
    if (sortOrder.includes(item.value)) {
      sortedItems.push(item)
    } else {
      remainingItems.push(item)
    }
  })

  const finalSortedItems = sortedItems.concat(remainingItems)
  return finalSortedItems
}

const SearchHit = ({ title, link, imageUrl }) => {
  return <EntryCard title={title} link={link} imageUrl={imageUrl} />
}

const HitsList = () => {
  const { hits, showMore, isLastPage } = useInfiniteHits()
  const { results } = useInstantSearch()

  if(results.query === "" && results._rawResults.length === 1) {
    return null;
  }

  return (
    <>
      {hits.length > 0 && <h4>Resultaten</h4>}
      <div className={hitsContainer}>
        {hits.map((hit) => {
          if (!hit.imageUrl) {
            hit.imageUrl =
              "//images.ctfassets.net/8kzekgmzhrvm/32zpVsrLSwTo9LVxUIjHIM/ecde2a45d6437064cdd45ee71574f463/placeholder3"
          }
          const processedImageUrl = imageApi.getSrc(hit.imageUrl, {
            w: 200,
            r: 10,
          })
          return (
            <SearchHit
              key={`search-hit-${hit.objectID}`}
              title={hit.title}
              link={hit.url}
              imageUrl={processedImageUrl}
            />
          )
        })}
      </div>
      {!isLastPage && (
        <button onClick={showMore} className={loadMoreButton}>
          <FaPlus width={12} height={12} color="#575757" />
          Laad meer
        </button>
      )}
    </>
  )
}

const SearchProvider = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure hitsPerPage={6} />

      <div className={inputs}>
        <CustomSearchBox placeholder="Zoeken" className={searchInput} />

        <MenuSelectWidget placeholder="Categorie" attribute="desk" />
        <MenuSelectWidget placeholder="Subcategorie" attribute="deskCategory" />
      </div>

      <PillMenu attribute="pillTypes" transformItems={transformItems} />

      <HitsList />
    </InstantSearch>
  )
}

export default SearchProvider
