import React from "react"
import ActionCard from "../action-card/action-card"
import linkBuilder from "../../modules/linkBuilder"
import EntryCard from "../entry-card/entry-card"

import { container, articleList, loadMoreButton } from "./featured-hero.module.css"
import { FaChevronRight } from "react-icons/fa"
import { navigate } from "gatsby"


const FeaturedHero = ({ hero, featuredArticles }) => {
    const uniqueIds = new Set();
    const filteredArticles = featuredArticles.nodes.filter(obj => {
        if (!uniqueIds.has(obj.id)) {
          uniqueIds.add(obj.id);
          return true;
        }
        return false;
      });

    return (
        <>
            <div className={container}>
                <ActionCard title={hero.featured.title} link={linkBuilder.article(hero.featured)} image={hero.featured.image} page='home' />
                <div className={articleList}>
                    {filteredArticles.slice(0, 5).map((article) => {
                        return (
                            <EntryCard key={article.id} title={article.title} link={linkBuilder.article(article)} image={article.image} />
                        )
                    })}
                </div>
            </div>
            <button className={loadMoreButton} onClick={() => navigate(linkBuilder.desk(hero))}>
                Meer {hero.name}<FaChevronRight size={16} color="#575757" />
            </button>
        </>
    )
}

export default FeaturedHero
