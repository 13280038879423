import React from 'react';
import { Pagination } from "@egjs/flicking-plugins";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";

import linkBuilder from '../../modules/linkBuilder';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { cardWrapper, cardTitle, cardIcon } from "./hero-carousel.module.css"

const HeroCarousel = ({ items }) => {
  const css = `
    .flicking-viewport {
      position: relative;
      overflow: hidden;
    }
  
    .flicking-viewport.vertical {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  
    .flicking-viewport.vertical>.flicking-camera {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .flicking-viewport.flicking-hidden>.flicking-camera>* {
      visibility: hidden;
    }
  
    .flicking-camera {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      z-index: 1;
      will-change: transform;
    }
  
    .flicking-camera>* {
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
  
  
    .flicking-pagination {
      position: relative;
      width: 100%;
      text-align: center;
      z-index: 2;
      margin-top: 20px;
    }
  
    .flicking-pagination-bullets,
    .flicking-pagination-scroll {
      font-size: 0;
    }
  
    .flicking-pagination-scroll {
      left: 50%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
      white-space: nowrap;
      overflow: hidden;
    }
  
    .flicking-pagination-scroll .flicking-pagination-slider {
      -webkit-transition: .2s transform;
      transition: .2s transform;
    }
  
    .flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,
    .flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {
      -webkit-transition: none;
      transition: none;
    }
  
    .flicking-pagination-bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 4px;
      border-radius: 50%;
      background-color: rgb(10 10 10 / 10%);
      cursor: pointer;
      font-size: 1rem;
    }
  
    .flicking-pagination-scroll .flicking-pagination-bullet {
      vertical-align: middle;
      position: relative;
      -webkit-transition: .2s transform;
      transition: .2s transform;
    }
  
    .flicking-pagination-bullet-active {
      background-color: #0046EB;
    }
  
    .flicking-pagination-scroll .flicking-pagination-bullet {
      vertical-align: middle;
      position: relative;
      -webkit-transition: .2s transform, .2s left;
      transition: .2s transform, .2s left;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }
  
    .flicking-pagination-scroll .flicking-pagination-bullet-active {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    .flicking-pagination-scroll .flicking-pagination-bullet-prev,
    .flicking-pagination-scroll .flicking-pagination-bullet-next {
      -webkit-transform: scale(0.66);
      -ms-transform: scale(0.66);
      transform: scale(0.66);
    }
  
    .flicking-pagination-scroll .flicking-pagination-bullet-prev2,
    .flicking-pagination-scroll .flicking-pagination-bullet-next2 {
      -webkit-transform: scale(0.33);
      -ms-transform: scale(0.33);
      transform: scale(0.33);
    }
  `;

  return (<>
    <Helmet>
      <style>
        {css}
      </style>
    </Helmet>
    <Flicking
      circular={true}
      plugins={[new Pagination({ type: 'bullet' })]}
      moveType="snap"
      hideBeforeInit={true}
      preventEventsBeforeInit={true}
    >
      {items.map((item) => {
        if (item.icon && item.icon.mimeType === "image/svg+xml") {
          return (
            <Link to={linkBuilder.desk(item)} key={item.id} style={{ margin: '0 10px' }}>
              <div className={cardWrapper}>
                <img className={cardIcon} src={item.icon.file.url} alt="SVG Icon for desk" />
                <p className={cardTitle}>{item.name}</p>
              </div>
            </Link>
          )
        }
        return null;
      })}
      <ViewportSlot>
        <div className="flicking-pagination"></div>
      </ViewportSlot>
    </Flicking>
  </>)
}

export default HeroCarousel;